<template>
  <section class="main">
    <TheHeader />
    <div class="container pt-5">
      <div class="row">
        <div class="col">
          <h3>Dados Cadastrais</h3>
          <hr />
        </div>
      </div>
      <div class="row"> 
        <div class="col">
          <nav aria-label="breadcrumb" id="breadcrumb">
            <ol class="breadcrumb">
                  <li class="breadcrumb-item"> 
                    <router-link to="/" style=" display: flex; align-items: center; "><span class="material-icons"> keyboard_arrow_left </span> Início</router-link>
                  </li>
                  <!--
              <li class="breadcrumb-item">
                <router-link to="/">Home</router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">Meu Perfil</li>
            -->
            </ol>
          </nav>
        </div>
      </div>
      <div class="row pt-3">
        <div class="col-md-3">
          <div
            class="nav flex-column nav-pills nav-perfil"
            id="v-pills-tab"
            role="tablist"
            aria-orientation="vertical"
          >
            <router-link class="nav-link" :to="{name: 'meuperfil'}">
              <span class="d-md-flex">Dados Cadastrais</span>
              <i class="material-icons">person</i>
            </router-link>
            <router-link class="nav-link" role="tab" :to="{name: 'compras'}" v-if="$wlType === 'pdv' && $store.state.user.group != 'gestor'">
              <span class="d-md-flex">Histórico de Compras</span>
              <i class="material-icons">work</i>
            </router-link>
            <router-link class="nav-link" role="tab" :to="{name: 'configuracoes'}">
              <span class="d-md-flex">Configurações</span>
              <i class="material-icons">settings</i>
            </router-link>
            <router-link class="nav-link" role="tab" :to="{name: 'script'}" v-if="$store.state.user.group == 'gestor'">
              <span class="d-md-flex">Script</span>
              <i class="material-icons">code</i> 
            </router-link>
            <router-link class="nav-link" role="tab" :to="{name: 'slide'}" v-if="$store.state.user.group == 'gestor'">
              <span class="d-md-flex">Slide</span>
              <i class="material-icons">collections</i>
            </router-link>
            <router-link class="nav-link" role="tab" :to="{name: 'Stripe'}" v-if="$store.state.user.group == 'gestor'">
              <span class="d-md-flex">Stripe</span>
              <i class="material-icons">account_balance_wallet</i>
            </router-link>
          </div>
        </div>
        <div class="col-md-9">
          <transition mode="out-in">
            <router-view></router-view>
          </transition>
        </div>
      </div>
    </div>

    <TheFooter />
  </section>
</template>
<script>
import TheHeader from "@/components/TheHeader.vue";
import TheFooter from "@/components/TheFooter.vue";
export default {
  name: "MeuPerfil",
  components: {
    TheHeader,
    TheFooter,
  },
  mounted() {
    this.$store.dispatch("getUserData");
  },
};
</script>

<style lang="scss" scoped>
@media screen and (max-width: 900px) {
  #v-pills-tab {
    display: flex;
    flex-direction: row !important;
    justify-content: space-between;
    margin-bottom: 1rem;
    a {
      width: 22%;
      text-align: center;
      justify-content: center;
      align-items: center;
      span {
        display: none;
      }
    }
  }
}
</style>